:root {
  --button-color: #383838;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes grow {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.container {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 0rem;
  padding: 1rem;
  position: relative;
  width: 100%;
  background-color: rgb(176, 201, 226);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  min-height: 80vh;
}

.searchInput {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  margin-bottom: 0.2rem;
  font-size: 1rem;
  border-radius: 10px;
  border: 2px solid #aaa;
  outline: none;
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.searchInput::placeholder {
  text-align: center;
}

.searchContainer {
  width: 100%;
}

.input:focus {
  border-color: #229091;
}

.CircularProgress {
  animation: fadeIn 0.3s ease-in;
}


.dropdown {
  position: absolute;
  animation: fadeIn 0.3s ease-in;
  width: 95%;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 40;
  margin-top: 0rem;
  background-color: white;
  box-sizing: border-box;
  visibility: hidden;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1); /* Added this line */
}


.dropdown.open {
  visibility: visible;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3); /* Added this line */
}

.closeButton {
  cursor: pointer;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.048); /* Black background with low opacity */
  /* Other styling as needed */
  order: 3; /* This places the closeButton at the bottom of the dropdown */
  list-style: none;
}


.dropdownItem {
  list-style: none;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  background-color: #fff;
}

.dropdownItem:hover {
  background-color: #ddd;
}

.dropdownItem[data-highlighted="true"] {
  background-color: #96D6D2;
  color: #222;
}

.requestStatus {
  cursor: 'help';
  padding: 10px;
  color: #333;
  text-align: 'right';
  font-size: '0.9rem';
}

.beachListContainer {
  padding: 20px;
}



.selectedList {
  background-color: #00000021;
  border-radius: 10px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style: none;
  /* Remove bullet points */
  padding: 1rem;
  /* Reset padding */
}

.selectedList:empty,
.beachesList:empty {
  height: 200px;
  background-color: #f5f5f5;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-style: italic;
}

.selectedItem {
  margin-bottom: 0.5rem;
  animation: grow 0.3s ease-out;
  animation-delay: calc(var(--i, 0) * 0.1s);
}

.sectionHeader {
  margin-top: 2rem;
  color: #131313;
  background-color: #ffffff81;
  padding: 10px;
  position: relative;
  width: calc(100% + 2rem);
  left: -1rem;
  box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}

.centerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddLocationAltIcon {
  font-size: 1.5em;
  margin: -0.1rem;
  margin-left: 0.2rem;
}

.drawBoundaryButton {
  display: 'flex';
  align-items: 'center';
  max-height: 2.2rem;
  border-radius: 6px;
  background-color: #167000d0;
  color: #fff;
  border: none;
  padding: 0.6rem 0rem 1rem 0rem;
  font-size: 0.7em;
  cursor: pointer;
  width: 100%;
  transition: transform .2s, box-shadow .2s, filter .2s, background-color .2s;
}

.searchButton {
  border-radius: 3px;
  background-color: var(--button-color);
  margin: 2rem;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  transition: transform .2s, box-shadow .2s, filter .2s, background-color .2s;
}

.searchButton:disabled {
  position: relative;
  background-color: gray;
  cursor: not-allowed;
}

.searchButton:hover {
  filter: brightness(110%);
}

.searchButton:active {
  filter: brightness(90%);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}


.error {
  color: red;
  margin-top: 1rem;
}

.beachesList {
  background-color: #00000021;
  border-radius: 10px;
  margin-top: 1rem;
  list-style: none;
  /* Remove bullet points */
  padding: 1rem;
  /* Reset padding */
}

.beachItem {
  margin-bottom: 0.5rem;
  animation: slideIn 0.5s ease-out;
  animation-delay: calc(var(--i, 0) * 0.1s);
}

.MuiCard-root {
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.warmestBeach .MuiCard-root {
  background-color: #fff0d6;
}

.CoastIcon {
  font-size: 1.2em;
}

.WaterIcon {
  font-size: 1.2em;
}

.settings-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}

.settings-button {
  border-radius: 3px;
  background-color: #2196f3;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  transition: transform .2s, box-shadow .2s, filter .2s, background-color .2s;
}

.settings-button:hover {
  filter: brightness(110%);
}

.settings-button:active {
  filter: brightness(90%);
}

.MuiMenu-paper {
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.MuiFormControl-root {
  margin-left: 1rem;
  width: 200px;
}

.MuiSelect-select {
  width: 100%;
}

.MuiButton-containedPrimary {
  background-color: #2196f3;
}


.settingsSection {
  color: #131313;
  background-color: #ffffff81;
  position: relative;
  width: calc(100% + 2rem);
  left: -1rem;
  box-sizing: border-box;
  padding: 5px;
  padding-bottom: 0rem;
  border-bottom-style: solid;
  border-bottom-width: 0.1rem;
  border-color: #1313133a;
}

.settingsSection * {
  font-size: 0.8rem;
}

.FilterBox {
  border-radius: 5px;
  margin-bottom: 1em;
  width: 100%;
}

.selectButton {
  background-color: #f0f0f091;
  color: #333;
  border: none;
  cursor: pointer;
  font-size: 0.7rem;
  padding: 5px 10px;
  margin: 5px 10px 5px 0;
  box-shadow: 0 0 0 0;
}

.selectButton:hover {
  background-color: rgba(36, 32, 32, 0.075);
  color: #333;
  font-size: 0.7rem;
  padding: 5px 10px;
  margin: 5px 10px 5px 0;
  box-shadow: 0 0 0 0;
}

.FilterGridContainer {
  align-items: center;
  justify-content: space-between;
  background-color: #1313130c;
  margin-top: 1rem;
  width: 100%;
  border-radius: 5px;
  padding: 0.5rem;
}

.TextFieldSelect {
  font-size: 0.8em;
  color: #333;
}

.TextFieldInput {
  font-size: 1em;
  color: #333;
  width: 10%;
}

.TextFieldInput input {
  font-size: 0.6rem;
  padding: 5px 0 5px 0;
  text-align: center;
}

.TextFieldInput label {
  font-size: 0.6rem;
  padding: 5px 0 5px 0;
  text-align: center;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield; /* for Firefox */
}

.SliderInputContainer {
  display: flex;
  justify-content: space-between;
  margin: 1em;
}

.Slider {
  padding: 0.5em 0;
  width: 100%;
  margin: 0rem auto;
  margin-left: 1rem;
  margin-right: 1rem;
}

.FilterButton {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.8em;
  margin: 1em 0;
  cursor: pointer;
  border-radius: 5px;
  transition-duration: 0.4s;
}

