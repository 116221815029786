.leaflet-tooltip {
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
}

.leaflet-popup-content {
    text-align: center;
    font-size: 16px;
}

.mapContainer {
    width: 100%;
    height: 40vw;
    border-radius: 10px;
}

@media screen and (max-width: 600px) {
    .mapContainer {
        height: 60vw;
    }
}

.marker {
    background-color: orange;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    opacity: 80%;
    border-color: rgba(0, 0, 0, 0.459);
    border-style: dashed;
    border-width: 1px;
    width: 40px;
    height: 40px;
    font-size: 12px;
    box-shadow: 0 0px 6px rgba(0, 0, 0, 0.801), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    margin: 10px;
}

.arrow {
    padding: 10px;
    color: gray;
}

.cardContent {
    text-align: center;
}