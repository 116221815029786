.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 500px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 900000;
    text-align: center;
  }
  
  .dialog p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .dialog button {
    padding: 10px 20px;
    margin: 0 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .dialog button:first-child {
    background-color: #4caf50;
    color: white;
  }
  
  .dialog button:last-child {
    background-color: #f44336;
    color: white;
  }
  