.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0.5rem;
    position: relative;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    text-align: left;
    color: #333;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
}

.container:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.hideButton {
    width: 100%;
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease-in-out;
}

.hideButton:hover {
    background-color: #555;
}

.guideContainer {
    margin-bottom: 0rem;
}

.guideLink {
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease-in-out;
}

.guideTitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: 0.1rem;
}

.guideDescription {
    font-size: 15px;
    text-align: center;
}

.guideLink:hover {
    color: #555;
}

.guideTextContainer {
    border: 1px solid #ddd; /* Light gray border */
    background-color: #f5f5f5;
    padding: 0.7rem;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.guideTextContainer:hover {
    background-color: #ddd;
}


.newsletterContainer {
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    padding: 0.7rem;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
}

.newsletterTitle {
    font-size: 20px;
    text-align: center;
    margin-bottom: 0.1rem;
}

.newsletterDescription {
    font-size: 15px;
    text-align: center;
    margin-bottom: 0.3rem;
}

.emailInput {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 0.3rem;
}

.signupButton {
    width: 100%;
    padding: 0.5rem;
    background-color: #318029;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease-in-out;
}

.signupButton:hover {
    background-color: #555;
}