/* BlogPost.module.css */
.h1 {
    color: #000000;
    font-size: 2em; /* This will be the largest heading */
    margin-bottom: 20px;
}

.h2 {
    color: #000000;
    font-size: 1.5em; /* Slightly smaller than h1 */
    margin-bottom: 20px;
}

.h3 {
    color: #000000;
    font-size: 1.25em; /* Slightly smaller than h2 */
    margin-bottom: 20px;
}

/* Continue in this manner for the remaining header levels (h4, h5, h6) if necessary. */


.postImage {
    width: 100%;
    max-width: 600px; /* Make the image a bit wider */
    height: auto;
    position: relative;
    overflow: hidden;
    margin: 0 auto; /* Center the image */
    display: block; /* To enable the image centering */
}

.postParagraph {
    color: #000000;
    font-size: 1em; /* Increase the size for better readability */
    line-height: 1.6; /* Increase line height for better readability */
    margin-bottom: 20px; /* Add some space between paragraphs for better content separation */
}

.postContainer {
    max-width: 600px; /* Increase the width for a bit more breathing room for your content */
    margin: 0rem auto; /* Increase the top margin and set auto for left and right */
    padding: 2rem; /* Increase the padding for more space around the content */
    position: relative;
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 2px 4px rgba(0, 0, 0, 0.08); /* Make the box shadow less prominent for a cleaner look */
    border-radius: 10px;
    min-height: 80vh;
    color: #000000;
}

.postCard {
    margin: 1em 0;
}