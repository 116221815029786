.canvas {
  position: fixed;
  opacity: 50%;
  z-index: 9999;
  cursor: none;
}

.nonInteractive {
  pointer-events: none;
}

.customCursor {
  position: fixed;
  background-color: rgba(0, 128, 0, 1);
  opacity: 50%;
  border-radius: 50%;
  pointer-events: none;
  z-index: 999;
}
