.toggle-button-cover {
    margin-top: 0.1rem;
    position: relative;
    width: 60px;
    height: 29px;
    background-color: #ccc;
    border-radius: 40px;
}

.label-font {
    font-size: 14px;
}


.checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 4;
}


.knobs {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.checkbox:checked + .knobs {
    left: calc(100% - 27px);
}

.layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 34px;
    transition: 0.3s;
}