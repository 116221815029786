.contactContainer img {
    max-width: 30%;
    height: auto;
}

.bioSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.bioImage {
    max-width: 50%;
    height: auto;
    margin-right: 2em;
}

.contactDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email a, .linkedin a {
    display: flex;
    align-items: center;
    margin: 10px 0;
    text-decoration: none;
    color: inherit;
}

.email a > svg, .linkedin a > svg {
    margin-right: 10px;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 0rem;
    padding: 1rem;
    position: relative;
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    min-height: 80vh;
    color: #000000; /* Use white color for the text as the background is blue */
  }

.contactContainer h1,
.contactContainer h2,
.contactContainer h3,
.contactContainer h4,
.contactContainer h5,
.contactContainer h6 {
  text-align: center;
}

.App .contactContainer .contactSectionHeader {
  color: #131313;
}

.contactSectionHeader {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #131313;
    background-color: #ffffffdc;
    padding: 10px;
    position: relative;
    width: calc(100% + 2rem);
    left: 0rem;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}

.contactContainer ol {
  padding-left: 1.5em;
}
  