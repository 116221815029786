:root {
  --light-blue: #286d8d;
  --dark-blue: #005986;
}

.App {
  text-align: center;
  padding: 1rem;
  background: -webkit-linear-gradient(top, var(--dark-blue) 60%, var(--light-blue) 100%);
  /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(top, var(--dark-blue) 60%, var(--light-blue) 100%);
  /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(top, var(--dark-blue) 60%, var(--light-blue) 100%);
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom, var(--dark-blue) 60%, var(--light-blue) 100%);
  /* Standard syntax */
  min-height: 100vh;
}

.mainTitle {
  color: white;
}

.nav-menu {
  margin: auto;
  margin-top: 0.5rem;
  display: flex;
  max-width: 800px;
  justify-content: space-evenly;
  padding: 0.5rem;
  border: none;
  background-color: #003651;
  color: white;
  animation: fadeIn 0.3s ease-in;
  border-radius: 10px;
  transition: transform .2s, box-shadow .2s, filter .2s, background-color .2s;
}

@media (max-width: 375px) {
  .nav-menu {
    flex-direction: column;
    /* stack the links vertically */
  }

  .nav-menu a {
    text-align: center;
    /* center the links */
    padding: 10px 0;
    /* adjust padding as needed */
  }
}

.nav-menu a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 1.2em;
  transition: transform .2s, box-shadow .2s, filter .2s, background-color .2s;
}

.nav-menu a:hover {
  filter: brightness(110%);
}

.nav-menu a:active {
  filter: brightness(90%);
}

.nav-menu .active {
  background-color: #000000;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}