.LocationSearch {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    padding: 5px;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.LocationSearchInput {
    width: 100%;
    padding: 5px;
    border: none;
    border-radius: 4px;
}

.LocationSearchResults {
    max-height: 200px; /* Limit the height */
    overflow-y: auto; /* Allow vertical scrolling */
    list-style: none;
    padding: 0;
    margin: 5px 0;
    max-height: 150px;
    overflow-y: auto;
}

.LocationSearchItem {
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
}

.LocationSearchItem:hover {
    background-color: #f0f0f0;
}
