.toolbar {
  display: flex;
  justify-content: space-around;
  background-color: #f9f9f9;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

/* CSS media query for screens smaller than 375px */
@media (max-width: 375px) {
  .toolbar {
    display:block;
  }

  /* Wrap the other buttons in a div and give it this class */
  .otherButtons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* Ensures the buttons will take up the full width of the toolbar */
  }
}

.sliderContainer {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 10px;
}

.slider {
  flex-grow: 1;
  margin: 0px 10px 0px 10px;
}

.numberInput {
  width: 50px;
  margin-left: 10px;
}

.button,
.modeButton,
.toggleDragButton {
  padding: 10px;
  border: none;
}

.button[disabled],
.modeButton[disabled] {
  color: gray;
}

.toggleDragButton {
  margin-right: auto;
}