.aboutContainer {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 0rem;
    padding: 1rem;
    position: relative;
    width: 100%;
    background-color: #f5f5f5;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    min-height: 80vh;
    text-align: left;
    color: #000000;
  }

.aboutContainer h1,
.aboutContainer h2,
.aboutContainer h3,
.aboutContainer h4,
.aboutContainer h5,
.aboutContainer h6 {
  text-align: center;
}

.App .aboutContainer .sectionHeader {
  color: #131313;
}


.sectionHeader {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #131313;
    background-color: #ffffffdc;
    padding: 10px;
    position: relative;
    width: calc(100% + 2rem);
    left: -1rem;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16);
}

.aboutContainer ol {
  padding-left: 1.5em;
}


.newsletterTitle {
  font-size: 20px;
  text-align: center;
  margin-bottom: 0.1rem;
}