.map {
    display: flex;
}

.locate {
    z-index: 100;
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
}

.saveContainer {
    display: flex;
    border-radius: 1rem 1rem 0rem 0rem;
    justify-content: space-around;
    background-color: #f9f9f9;
    padding: 10px;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.saveButtonGroup {
    width: 50%;
    padding-top: 1px;
}

.saveButtonGroup * {
    width: 40%;
    font-size: 0.9rem;
}

@media (max-width: 375px) {
    .saveContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .saveButtonGroup {
        padding-top: 5px;
    }

    .saveButtonGroup * {
        width: 40%;
        font-size: 0.9rem;
    }
}

.saveInput {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    margin-bottom: 0.2rem;
    font-size: 1rem;
    border-radius: 10px;
    border: 2px solid #aaa;
    outline: none;
    display: block;
    margin-left: 0;
    margin-right: auto;
}

.saveButton {
    background-color: green;
    color: white;
    border: none;
    padding: 10px 5px;
    margin: 2px;
    margin-top: 0px;
    border-radius: 5px;
    cursor: pointer;
}

.saveButton:disabled {
    background-color: lightgray;
    color: gray;
    cursor: not-allowed;
}

.discardButton {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 5px;
    margin: 2px;
    margin-top: 0px;
    border-radius: 5px;
    cursor: pointer;
}

.instruction {
    background-color: #f9f9f9;
    text-align: center;
    font-size: 0.9em;
    padding-bottom: 5px;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    padding: 20px;
    z-index: 1000;
    border: 1px solid #000000;
    box-shadow: 0 0px 10px rgba(0, 0, 0, 1);
    z-index: 999999;
}